// Packages:
import React, { useEffect } from 'react'
import styled from 'styled-components'


// Constants:
import FIREBASE_CONSTANTS from '../../firebase/constants'


// Styles:
const Wrapper = styled.div``


// Functions:
const Blog = (props: { id: string, origin: string }) => {
  // Constants:
  const [ id, origin ] = [ props.id, props.origin ]

  // Effects:
  useEffect(() => {
    if (id && window) {
      const { doc, getDoc } = require('firebase/firestore')
      const FIREBASE = require('../../firebase')
      (async () => {
        const docRef = doc(FIREBASE.DB.FIRESTORE, FIREBASE_CONSTANTS.FIRESTORE.COLLECTIONS.BLOGS, id)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
          console.log('Document data: ', docSnap.data())
        }
      })()
    }
    
    return () => {
      
    }
  }, [])
  
  // Return:
  return (
    <Wrapper>
    </Wrapper>
  )
}


// Exports:
export default Blog
