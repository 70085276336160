// Packages:
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore'
import { getDatabase } from 'firebase/database'


// Constants:
const firebaseConfig = {
  apiKey: 'AIzaSyDzQRoAK8HMu5SeuUpiKoU3l7U4o9MsCTw',
  authDomain: 'diragb-dev.firebaseapp.com',
  databaseURL: 'https://diragb-dev-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'diragb-dev',
  storageBucket: 'diragb-dev.appspot.com',
  messagingSenderId: '949298087508',
  appId: '1:949298087508:web:1243fb6de778d2d4bc8fc1',
  measurementId: 'G-6J82WW2RDL'
}
const APP = initializeApp(firebaseConfig)


// Exports:
export default {
  APP,
  ANALYTICS: getAnalytics(),
  DB: {
    FIRESTORE: getFirestore(APP),
    REALTIME: getDatabase(APP)
  }
}
